import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import '../../styles/LoginAdminPage.css';

const LoginAdminPage = () => {
  const navigate = useNavigate();
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState('');
  const [loading, setLoading] = useState(false);

  const handleLogin = async (e) => {
    e.preventDefault();
    setError('');
    setLoading(true);
  
    // Verificăm dacă au fost completate username și parola
    if (!username.trim() || !password.trim()) {
      setError('Please enter both username and password.');
      setLoading(false);
      return;
    }
  
    try {
      const apiUrl = process.env.REACT_APP_API_URL?.replace(/\/$/, '') || 'https://api-backend.full-trucking-service.com/api';
      const response = await axios.post(`${apiUrl}/admin/auth/login`, {
        username,
        password,
      }, {
        headers: { 'Content-Type': 'application/json' },
        withCredentials: true,
      });
      
      console.log('Response Headers:', response.headers);  // Log response headers for debugging
      
      // Check for JSON response
      if (response.headers['content-type'].includes('application/json')) {
        if (response.data && response.data.token) {
          sessionStorage.setItem('token', response.data.token); // Store the JWT token
          navigate('/manage');  // Redirect to the admin page
        } else {
          throw new Error('Invalid login response format.');
        }
      } else {
        console.log('Received HTML instead of JSON:', response.data);
        setError('Server returned an HTML response. This might be a redirection issue.');
      }
    } catch (err) {
      console.error('Error during login:', err);
      setError('Server did not respond or network error.');
    }
  };
      
  return (
    <div className="login-page">
      <h1>Admin Login</h1>
      <form className="login-form" onSubmit={handleLogin}>
        <input
          type="text"
          placeholder="Enter username"
          value={username}
          onChange={(e) => setUsername(e.target.value)}
          required
        />
        <input
          type="password"
          placeholder="Enter password"
          value={password}
          onChange={(e) => setPassword(e.target.value)}
          required
        />
        <button type="submit" disabled={loading}>
          {loading ? 'Logging in...' : 'Login'}
        </button>
        {error && <p className="error">{error}</p>}
      </form>
    </div>
  );
};

export default LoginAdminPage;
