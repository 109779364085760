import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import {
  Chart as ChartJS,
  ArcElement,
  CategoryScale,
  LinearScale,
  BarElement,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend
} from 'chart.js';
import { Doughnut, Bar, Line } from 'react-chartjs-2';
import axios from 'axios';
import styles from '../../styles/DashboardAdminPage.module.css'; // Import the correct CSS module

ChartJS.register(
  ArcElement,
  CategoryScale,
  LinearScale,
  BarElement,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend
);

const DashboardAdminPage = () => {
  const navigate = useNavigate();
  const [clients, setClients] = useState([]);
  const [packageCounts, setPackageCounts] = useState({});
  const [paymentCounts, setPaymentCounts] = useState({});
  const [upgradeCounts, setUpgradeCounts] = useState(0);
  const [downgradeCounts, setDowngradeCounts] = useState(0);
  const [deactivatedCounts, setDeactivatedCounts] = useState(0);
  const [deletedCounts, setDeletedCounts] = useState(0);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState('');

  useEffect(() => {
    const fetchClients = async () => {
      try {
        const apiUrl = process.env.REACT_APP_API_URL?.replace(/\/$/, '') || 'https://api-backend.full-trucking-service.com';

        // Fetch clients data
        const response = await axios.get(`${apiUrl}/admin/dashboard`, {
          withCredentials: true, // Send session cookies
        });

        const clientsData = response.data.clients;

        if (Array.isArray(clientsData)) {
          setClients(clientsData);

          // Count packages
          const counts = clientsData.reduce((acc, client) => {
            acc[client.package] = (acc[client.package] || 0) + 1;
            return acc;
          }, {});
          setPackageCounts(counts);

          // Count payment types
          const paymentTypes = clientsData.reduce((acc, client) => {
            acc[client.subscriptionType] = (acc[client.subscriptionType] || 0) + 1;
            return acc;
          }, {});
          setPaymentCounts(paymentTypes);

          // Count account actions
          const upgrade = clientsData.filter(client => client.upgraded).length;
          const downgrade = clientsData.filter(client => client.downgraded).length;
          const deactivated = clientsData.filter(client => !client.active).length;
          const deleted = clientsData.filter(client => client.deleted).length;

          setUpgradeCounts(upgrade);
          setDowngradeCounts(downgrade);
          setDeactivatedCounts(deactivated);
          setDeletedCounts(deleted);
        } else {
          setError('Invalid data format received');
        }

        setLoading(false);
      } catch (err) {
        setError('Failed to fetch data. Please try again later.');
        setLoading(false);
        console.error(err);
      }
    };

    fetchClients();
  }, []);

  const handleLogout = async () => {
    const apiUrl = process.env.REACT_APP_API_URL?.replace(/\/$/, '') || 'https://api-backend.full-trucking-service.com';
    
    try {
      await axios.post(`${apiUrl}/admin/auth/logout`, {}, { withCredentials: true });
      navigate('/admin');
    } catch (err) {
      console.error('Failed to logout:', err);
    }
  };

  if (loading) {
    return <div>Loading...</div>;
  }

  if (error) {
    return <div className={styles.error}>{error}</div>;
  }

  const packageData = {
    labels: Object.keys(packageCounts),
    datasets: [
      {
        label: 'Packages',
        data: Object.values(packageCounts),
        backgroundColor: ['#FF6384', '#36A2EB', '#FFCE56', '#4BC0C0'],
      },
    ],
  };

  const paymentData = {
    labels: Object.keys(paymentCounts),
    datasets: [
      {
        label: 'Payment Methods',
        data: Object.values(paymentCounts),
        backgroundColor: ['#FF6384', '#36A2EB', '#FFCE56'],
      },
    ],
  };

  const upgradeData = {
    labels: ['Upgrades', 'Downgrades', 'Deactivated', 'Deleted'],
    datasets: [
      {
        label: 'Account Actions',
        data: [upgradeCounts, downgradeCounts, deactivatedCounts, deletedCounts],
        backgroundColor: ['#36A2EB', '#FF6384', '#FFCE56', '#4BC0C0'],
      },
    ],
  };

  const clientGrowthData = {
    labels: clients.map(client => new Date(client.createdAt).toLocaleDateString()),
    datasets: [
      {
        label: 'Client Growth Over Time',
        data: clients.map((_, index) => index + 1),
        fill: false,
        backgroundColor: '#36A2EB',
        borderColor: '#36A2EB',
      },
    ],
  };

  return (
    <div className={styles.dashboardPage}>
      <h1>Admin Dashboard</h1>
      <div className={styles.chartContainer}>
        <h2>Package Distribution</h2>
        <Doughnut data={packageData} />
      </div>
      <div className={styles.chartContainer}>
        <h2>Payment Methods Distribution</h2>
        <Bar data={paymentData} />
      </div>
      <div className={styles.chartContainer}>
        <h2>Account Actions</h2>
        <Bar data={upgradeData} />
      </div>
      <div className={styles.chartContainer}>
        <h2>Client Growth Over Time</h2>
        <Line data={clientGrowthData} />
      </div>
      <div className={styles.buttonGroup}>
        <button onClick={() => navigate('/manage')}>Manage Accounts</button>
        <button onClick={handleLogout}>Logout</button>
      </div>
    </div>
  );
};

export default DashboardAdminPage;
